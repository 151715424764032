<template>
  <div>
    <b-form-group :disabled="disabled">
      <div class="d-inline-flex">
        <label>Hoá đơn đỏ </label>
        <b-checkbox v-model="billData.companyBill" class="ml-2"></b-checkbox>
      </div>

      <b-form-radio-group
        v-model="vatInvoice.vatCompanyType"
        v-if="billData.companyBill"
        class="mt-2"
        :options="[
          { text: 'Công ty', value: 1 },
          { text: 'Cá nhân', value: 2 },
        ]"
        name="radio-inline"
      ></b-form-radio-group>
    </b-form-group>
    <b-form-group v-show="billData.companyBill" :disabled="disabled">
      <label v-if="vatInvoice.vatCompanyType === 2" class="mt-2"
        >Mã số cá nhân:</label
      >
      <label v-else class="mt-2">Mã số thuế:</label>
      <b-form-input
        :state="stateTaxCode"
        class="input-style"
        size="sm"
        trim
        v-model="vatInvoice.taxCode"
        :placeholder="
          vatInvoice.vatCompanyType === 2
            ? 'Nhập mã số cá nhân'
            : 'Nhập mã số thuế'
        "
      ></b-form-input>
      <b-form-invalid-feedback
        :state="stateTaxCode"
        >Mã số thuế không hợp lệ</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group v-show="billData.companyBill" :disabled="disabled">
     <div class="d-flex justify-content-between align-items-center">
        <label v-if="vatInvoice.vatCompanyType === 2">Họ và tên:</label>
        <label v-else>Tên công ty:</label>
        <a class="mb-3" href="http://tracuunnt.gdt.gov.vn/tcnnt/mstdn.jsp" target="_blank">Tra cứu Thuế <i class="flaticon2-search text-primary"></i></a> 
     </div>
      <b-form-input
        v-b-popover.hover.bottom="vatInvoice.companyName" 
        class="input-style"
        size="sm"
        type="text"
        v-model="vatInvoice.companyName"
        :placeholder="
          vatInvoice.vatCompanyType === 2
            ? 'Nhập họ và tên'
            : 'Nhập tên công ty'
        "
      ></b-form-input>
    </b-form-group>
    <b-form-group v-show="billData.companyBill" :disabled="disabled">
      <label v-if="vatInvoice.vatCompanyType === 2">Địa chỉ:</label>
      <label v-else>Địa chỉ công ty:</label>
      <b-form-input
        v-b-popover.hover.bottom="vatInvoice.companyAddress" 
        class="input-style"
        size="sm"
        type="text"
        v-model="vatInvoice.companyAddress"
        :placeholder="
          vatInvoice.vatCompanyType === 2
            ? 'Nhập địa chỉ'
            : 'Nhập địa chỉ công ty'
        "
      ></b-form-input>
    </b-form-group>
    <b-form-group v-show="billData.companyBill" :disabled="disabled">
      <label>Email:</label>
      <b-form-input
        :state="stateEmail"
        v-b-popover.hover.bottom="vatInvoice.email" 
        class="input-style"
        size="sm"
        type="email"
        v-model="vatInvoice.email"
        placeholder="Email, ngăn cách nhiều email bởi dấu ' ; '"
        trim
      ></b-form-input>
      <b-form-invalid-feedback
        :state="stateEmail"
        >Email không hợp lệ</b-form-invalid-feedback
      >
    </b-form-group>
  </div>
</template>

<script>
import { cloneDeep, isValidEmail } from '@/utils/common';
export default {
  props: ['billData', 'disabled'],
  data() {
    return {
      vatInvoice: {
        vatCompanyType: 1,
        taxCode: '',
        companyAddress: '',
        companyName: '',
        email: '',
        phone:''
      },
    };
  },
  watch: {
    vatInvoice: {
      handler: function (newValue) {
        this.billData.vatInvoice = newValue;
      },
      deep: true,
    },
  },
  created() {
    this.vatInvoice = cloneDeep(this.billData.vatInvoice);
  },
  computed: {
    stateTaxCode() {
      if(!this.vatInvoice.taxCode) return null;

      const isTenNumber = /^[0-9]{10}$/.test(this.vatInvoice.taxCode);
      const isTenDashThreeNumber =  /(\d{10}-\d{3})$/.test(this.vatInvoice.taxCode)

      const state =  isTenNumber || isTenDashThreeNumber;
      this.$emit('update:isValidTaxCode', state);

      return state;
    },
    
    stateEmail() {
      if(!this.vatInvoice.email) return null;

      const { email } = this.vatInvoice;
      const emails = email.split(';');

      let state = true;
      for (const item of emails) {
        const stringTrim = item.trim();

        if(!this.isValidEmail(stringTrim)) {
            state = false;
        }
      }
      this.$emit('update:isValidEmail', state);

      return state
    },
  },
  methods:{
    isValidEmail
  }
};
</script>